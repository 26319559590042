import React, {useCallback, useContext} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {home} from 'modules/routes';

import {
  BorrowersSearch,
  Box,
  Flex,
  LenderLogo,
  Link,
  Navbar,
  Text,
} from '@renofi/components-internal';
import {useLogout} from '@renofi/utilities/src/hooks';
import Cross from '@renofi/icons/src/Cross';
import Person from '@renofi/icons/src/PersonCircle';
import {dangerRegular} from '@renofi/theme/src';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

import {Logo, Menu, NavTab, LogoLink} from './styled';
import person from './person.svg';
import renofi from './renofi.svg';

const Header = ({
  email,
  firstName,
  lastName,
  path,
  lender,
  ruEnabled,
  impersonation,
}) => {
  const {LOGIN_FRONTEND_URL} = useContext(ConfigContext);
  const {logout} = useLogout();
  const history = useHistory();

  const onSelectProject = useCallback((projectId, project) => {
    const {wholesale, renovationReadyFile, renovationReadyFileId} =
      project || {};
    const rrfId = renovationReadyFile?.id || renovationReadyFileId;
    const url =
      wholesale && !rrfId
        ? `/underwriting/${projectId}`
        : `/members/${rrfId}/details`;
    history.push(url);
  }, []);

  return (
    <>
      {impersonation && (
        <Navbar
          wide
          fixed
          height={60}
          css={{
            color: 'white',
            backgroundColor: dangerRegular,
          }}>
          <Flex width={1} justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box mr="8px">
                <Person color="white" />
              </Box>
              <Text>
                Impersonating {firstName} {lastName} ({email})
              </Text>
            </Flex>
            <Box>
              <a href={LOGIN_FRONTEND_URL}>
                <Cross color="white" />
              </a>
            </Box>
          </Flex>
        </Navbar>
      )}
      <Navbar
        wide
        fixed
        top={impersonation ? 60 : 0}
        css={{zIndex: '1001 !important'}}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Link to={home()}>
              <img height={19} src={renofi} alt="RenoFi" />
            </Link>
            <Logo ml={20}>
              <LogoLink to={home()}>
                <LenderLogo css={{height: 31}} lender={lender} />
              </LogoLink>
            </Logo>
            {email && (
              <Flex ml={28}>
                <NavTab active={path === '/dashboard'} to="/dashboard">
                  Dashboard
                </NavTab>
                <NavTab active={path.startsWith('/members')} to="/members">
                  Homeowners
                </NavTab>
                {/*<NavTab*/}
                {/*  active={path.startsWith('/underwriting')}*/}
                {/*  to="/underwriting">*/}
                {/*  Request underwriting*/}
                {/*</NavTab>*/}
                <NavTab
                  active={path.startsWith('/exceptions')}
                  to="/exceptions">
                  Exception requests
                </NavTab>
                <NavTab active={path.startsWith('/settings')} to="/settings">
                  Settings
                </NavTab>
                {ruEnabled && (
                  <NavTab
                    active={path.startsWith('/underwriting')}
                    to="/underwriting">
                    Request underwriting
                  </NavTab>
                )}
              </Flex>
            )}
          </Flex>
          <Flex alignItems="center">
            <BorrowersSearch
              width={260}
              onSelectProject={onSelectProject}
              searchByRuId
            />
            {email && (
              <Menu local={false} label={<img width={20} src={person} />}>
                <Link onClick={logout}>Sign out</Link>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  email: PropTypes.string,
  path: PropTypes.string,
  lender: PropTypes.shape({
    logoObjectName: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  impersonation: PropTypes.bool,
  ruEnabled: PropTypes.bool,
};

export default Header;
